import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/Reports/ContactUsSurveyAnswer',
    name: 'ContactUsSurveyAnswer',
    meta: {
      title: 'Encuesta de Satisfacción de la Mesa de Ayuda',
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "ContactUsSurveyAnswer" */ '../views/Reports/ContactUsSurveyAnswer.vue')
  },

  {
    path: '/ContactUs/Browse',
    name: 'BrowseContactUs',
    meta: {
      title: 'Consulta General de la Mesa de Ayuda',
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "BrowseContactUs" */ '../views/ContactUs/BrowseContactUs.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach ((<any>window).VueUtils.RouterBeforeEach);

export default router
